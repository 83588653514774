import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _763c1c40 = () => interopDefault(import('../src/pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _b7832590 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _390d0ea9 = () => interopDefault(import('../src/pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _6568d1f8 = () => interopDefault(import('../src/pages/initial-settings/company-profile.vue' /* webpackChunkName: "pages/initial-settings/company-profile" */))
const _6ca7077a = () => interopDefault(import('../src/pages/initial-settings/invite-members.vue' /* webpackChunkName: "pages/initial-settings/invite-members" */))
const _225073a3 = () => interopDefault(import('../src/pages/initial-settings/preferences.vue' /* webpackChunkName: "pages/initial-settings/preferences" */))
const _3d21890c = () => interopDefault(import('../src/pages/initial-settings/user-profile.vue' /* webpackChunkName: "pages/initial-settings/user-profile" */))
const _1c7eacb9 = () => interopDefault(import('../src/pages/sessions/callback.vue' /* webpackChunkName: "pages/sessions/callback" */))
const _054e930d = () => interopDefault(import('../src/pages/sessions/invitation.vue' /* webpackChunkName: "pages/sessions/invitation" */))
const _740fd9d6 = () => interopDefault(import('../src/pages/contents/_uuid/index.vue' /* webpackChunkName: "pages/contents/_uuid/index" */))
const _2ff3f778 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds" */))
const _44319e09 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds/new.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds/new" */))
const _3e796e60 = () => interopDefault(import('../src/pages/companies/_company_id/customers.vue' /* webpackChunkName: "pages/companies/_company_id/customers" */))
const _6fc88361 = () => interopDefault(import('../src/pages/companies/_company_id/customers/new.vue' /* webpackChunkName: "pages/companies/_company_id/customers/new" */))
const _fd79a684 = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/index" */))
const _54a166ce = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/edit" */))
const _6a544dc4 = () => interopDefault(import('../src/pages/companies/_company_id/expiration.vue' /* webpackChunkName: "pages/companies/_company_id/expiration" */))
const _c4355f58 = () => interopDefault(import('../src/pages/companies/_company_id/members.vue' /* webpackChunkName: "pages/companies/_company_id/members" */))
const _54f248e4 = () => interopDefault(import('../src/pages/companies/_company_id/profile.vue' /* webpackChunkName: "pages/companies/_company_id/profile" */))
const _5a9baedc = () => interopDefault(import('../src/pages/companies/_company_id/projects/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/index" */))
const _f59a8e06 = () => interopDefault(import('../src/pages/companies/_company_id/reports/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/index" */))
const _4312220b = () => interopDefault(import('../src/pages/companies/_company_id/setting.vue' /* webpackChunkName: "pages/companies/_company_id/setting" */))
const _49df4ec3 = () => interopDefault(import('../src/pages/companies/_company_id/sharing-contents/index.vue' /* webpackChunkName: "pages/companies/_company_id/sharing-contents/index" */))
const _c39d4eee = () => interopDefault(import('../src/pages/companies/_company_id/unavailability.vue' /* webpackChunkName: "pages/companies/_company_id/unavailability" */))
const _1d40197c = () => interopDefault(import('../src/pages/companies/_company_id/user-profile.vue' /* webpackChunkName: "pages/companies/_company_id/user-profile" */))
const _81aa0740 = () => interopDefault(import('../src/pages/companies/_company_id/projects/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/new" */))
const _93fe5e40 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/index" */))
const _a915b10c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id" */))
const _477eab06 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/index" */))
const _b9349930 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/index" */))
const _4427b4b6 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/detail.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/detail" */))
const _31fbda7f = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/member.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/member" */))
const _a00c47ac = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/reports.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/reports" */))
const _39017382 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/index" */))
const _27cf44b6 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/new" */))
const _2be451ac = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/posts/index" */))
const _f1405bc4 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/fullscreen" */))
const _ba9e1616 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/preview" */))
const _a8cbd4c6 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/print/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/print/index" */))
const _694ad0cb = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/index" */))
const _5420ff12 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/fullscreen" */))
const _5480214c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/index" */))
const _51337802 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/index" */))
const _7266af00 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/edit" */))
const _7f030634 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index" */))
const _27e43926 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/edit" */))
const _38cf82b5 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/preview" */))
const _41dd0d68 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/index" */))
const _8d249328 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/edit" */))
const _4a0b4954 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/preview" */))
const _4a53c8e4 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/edit" */))
const _377d2d08 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/pdf" */))
const _f65afd8c = () => interopDefault(import('../src/pages/companies/_company_id/project/_id/schedule/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/project/_id/schedule/pdf" */))
const _c77de4d8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/companies",
      component: _763c1c40,
      name: "companies"
    }, {
      path: "/logout",
      component: _b7832590,
      name: "logout"
    }, {
      path: "/sign_in",
      component: _390d0ea9,
      name: "sign_in"
    }, {
      path: "/initial-settings/company-profile",
      component: _6568d1f8,
      name: "initial-settings-company-profile"
    }, {
      path: "/initial-settings/invite-members",
      component: _6ca7077a,
      name: "initial-settings-invite-members"
    }, {
      path: "/initial-settings/preferences",
      component: _225073a3,
      name: "initial-settings-preferences"
    }, {
      path: "/initial-settings/user-profile",
      component: _3d21890c,
      name: "initial-settings-user-profile"
    }, {
      path: "/sessions/callback",
      component: _1c7eacb9,
      name: "sessions-callback"
    }, {
      path: "/sessions/invitation",
      component: _054e930d,
      name: "sessions-invitation"
    }, {
      path: "/contents/:uuid?",
      component: _740fd9d6,
      name: "contents-uuid"
    }, {
      path: "/companies/:company_id/construction-kinds",
      component: _2ff3f778,
      name: "companies-company_id-construction-kinds",
      children: [{
        path: "new",
        component: _44319e09,
        name: "companies-company_id-construction-kinds-new"
      }]
    }, {
      path: "/companies/:company_id/customers",
      component: _3e796e60,
      name: "companies-company_id-customers",
      children: [{
        path: "new",
        component: _6fc88361,
        name: "companies-company_id-customers-new"
      }, {
        path: ":id",
        component: _fd79a684,
        name: "companies-company_id-customers-id"
      }, {
        path: ":id/edit",
        component: _54a166ce,
        name: "companies-company_id-customers-id-edit"
      }]
    }, {
      path: "/companies/:company_id/expiration",
      component: _6a544dc4,
      name: "companies-company_id-expiration"
    }, {
      path: "/companies/:company_id/members",
      component: _c4355f58,
      name: "companies-company_id-members"
    }, {
      path: "/companies/:company_id/profile",
      component: _54f248e4,
      name: "companies-company_id-profile"
    }, {
      path: "/companies/:company_id/projects",
      component: _5a9baedc,
      name: "companies-company_id-projects"
    }, {
      path: "/companies/:company_id/reports",
      component: _f59a8e06,
      name: "companies-company_id-reports"
    }, {
      path: "/companies/:company_id/setting",
      component: _4312220b,
      name: "companies-company_id-setting"
    }, {
      path: "/companies/:company_id/sharing-contents",
      component: _49df4ec3,
      name: "companies-company_id-sharing-contents"
    }, {
      path: "/companies/:company_id/unavailability",
      component: _c39d4eee,
      name: "companies-company_id-unavailability"
    }, {
      path: "/companies/:company_id/user-profile",
      component: _1d40197c,
      name: "companies-company_id-user-profile"
    }, {
      path: "/companies/:company_id/projects/new",
      component: _81aa0740,
      name: "companies-company_id-projects-new"
    }, {
      path: "/companies/:company_id/files/:id?",
      component: _93fe5e40,
      name: "companies-company_id-files-id"
    }, {
      path: "/companies/:company_id/projects/:id",
      component: _a915b10c,
      children: [{
        path: "",
        component: _477eab06,
        name: "companies-company_id-projects-id"
      }, {
        path: "board_posts",
        component: _b9349930,
        name: "companies-company_id-projects-id-board_posts"
      }, {
        path: "detail",
        component: _4427b4b6,
        name: "companies-company_id-projects-id-detail"
      }, {
        path: "member",
        component: _31fbda7f,
        name: "companies-company_id-projects-id-member"
      }, {
        path: "reports",
        component: _a00c47ac,
        name: "companies-company_id-projects-id-reports"
      }, {
        path: "schedule",
        component: _39017382,
        name: "companies-company_id-projects-id-schedule"
      }, {
        path: "board_posts/new",
        component: _27cf44b6,
        name: "companies-company_id-projects-id-board_posts-new"
      }, {
        path: "board_posts/posts",
        component: _2be451ac,
        name: "companies-company_id-projects-id-board_posts-posts"
      }, {
        path: "schedule/fullscreen",
        component: _f1405bc4,
        name: "companies-company_id-projects-id-schedule-fullscreen"
      }, {
        path: "schedule/preview",
        component: _ba9e1616,
        name: "companies-company_id-projects-id-schedule-preview"
      }, {
        path: "schedule/print",
        component: _a8cbd4c6,
        name: "companies-company_id-projects-id-schedule-print"
      }, {
        path: "schedule/view",
        component: _694ad0cb,
        name: "companies-company_id-projects-id-schedule-view"
      }, {
        path: "schedule/view/fullscreen",
        component: _5420ff12,
        name: "companies-company_id-projects-id-schedule-view-fullscreen"
      }, {
        path: "board_posts/:board_id",
        component: _5480214c,
        name: "companies-company_id-projects-id-board_posts-board_id"
      }, {
        path: "files/:file_id",
        component: _51337802,
        name: "companies-company_id-projects-id-files-file_id"
      }, {
        path: "board_posts/:board_id/edit",
        component: _7266af00,
        name: "companies-company_id-projects-id-board_posts-board_id-edit"
      }, {
        path: "board_posts/:board_id/files",
        component: _7f030634,
        name: "companies-company_id-projects-id-board_posts-board_id-files"
      }, {
        path: "files/:file_id/edit",
        component: _27e43926,
        name: "companies-company_id-projects-id-files-file_id-edit"
      }, {
        path: "files/:file_id/preview",
        component: _38cf82b5,
        name: "companies-company_id-projects-id-files-file_id-preview"
      }]
    }, {
      path: "/companies/:company_id/reports/:id",
      component: _41dd0d68,
      name: "companies-company_id-reports-id"
    }, {
      path: "/companies/:company_id/files/:id?/edit",
      component: _8d249328,
      name: "companies-company_id-files-id-edit"
    }, {
      path: "/companies/:company_id/files/:id?/preview",
      component: _4a0b4954,
      name: "companies-company_id-files-id-preview"
    }, {
      path: "/companies/:company_id/reports/:id/edit",
      component: _4a53c8e4,
      name: "companies-company_id-reports-id-edit"
    }, {
      path: "/companies/:company_id/reports/:id/pdf",
      component: _377d2d08,
      name: "companies-company_id-reports-id-pdf"
    }, {
      path: "/companies/:company_id/project/:id?/schedule/pdf",
      component: _f65afd8c,
      name: "companies-company_id-project-id-schedule-pdf"
    }, {
      path: "/",
      component: _c77de4d8,
      name: "index"
    }],

    fallback: false
  })
}
