import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ja';
dayjs.extend(relativeTime);
dayjs.locale('ja');
var Formats = {
    default: 'YYYY/MM/DD HH:mm',
    'y/m/d': 'YYYY/MM/DD',
    'y-m-d': 'YYYY-MM-DD',
    'y.m.d': 'YYYY. MM. DD',
    'y.m.d-HH:mm': 'YYYY. MM. DD HH:mm',
    'md-str': 'M' + '月' + 'D' + '日',
    'ja-ymd': 'YYYY年MM月DD日',
    'ja-ymd-ddd': 'YYYY年M月D日（ddd）'
};
var dateFormat = function (value, format, isRelative) {
    if (format === void 0) { format = 'default'; }
    if (isRelative === void 0) { isRelative = false; }
    if (!value) {
        return '';
    }
    if (isRelative) {
        return dayjs(value).fromNow();
    }
    if (Object.keys(Formats).includes(format)) {
        return dayjs(value).format(Formats[format]);
    }
    return dayjs(value).format(format);
};
export default dateFormat;
